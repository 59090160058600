@keyframes slide {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .animation-slide {
    animation: slide 10s linear infinite;
  }
  