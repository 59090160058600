#slide img{
    box-shadow: 1px 4px 5px 1px rgb(68, 164, 228) ;
    border: 1px solid rgb(68, 164, 228);
    transition: .8s;
    border-radius: 20px;
    /* transform:scale(1.2) */
}
#slide img:hover{
    translate: 0px -25px ;
}
h4{
    text-decoration: overline;
    text-align: center;
    color: black;
    font-size: 30px;
    
}